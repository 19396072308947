import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMutation, useQuery } from 'react-query';
import { User } from 'api/resources/models/User';
import { useAppSelector } from 'core/store';
import { getUser, getHelpContext } from 'api/resources/userService';
import {
  getMessageQueues,
  getMessageQueuesXML,
} from 'api/resources/messageQueue';
import { getFNOLArchives, getFNOLDetails, getFnolQueues, getMessageArchives, getFnolXML } from 'api/resources/fnol';
import {
  createMaintenanceMessages,
  getCompanies,
  getMaintenanceMessage,
  getMaintenanceMessages,
  removeMaintenanceMessage,
  updateMaintenanceMessages,
} from 'api/resources/maintenanceMessages';
import { queryClient } from 'api/client';
import {
  AdminAddExternalConfigurationModel,
  AdminAddRepairerConnectionModel,
  AdminJobStatusUpdateModel,
  AdminLockRepairerModel,
  AdminMaintenanceMessageModel,
  AdminReactivateRepairerModel,
  AdminRemoveRepairerConnectionModel,
  AdminRepairerModel,
  AdminTerminateRepairerModel,
  AdminWPNameChangeModel,
  QueueType,
} from 'api/resources/models/AutoGenerated';
import { AxiosError } from 'axios';
import { jobStatusUpdate, wpNameChange } from 'api/resources/forceUpdateStatus';
import {
  addConnection,
  addFnol,
  createRepairer,
  editRepairer,
  getAvailableWorkProviders,
  getConnectionSettings,
  getFnolConfigs,
  getRepairer,
  getRepairers,
  getRepairerWorkProviders,
  lockRepairer,
  reactivateRepairer,
  removeConnection,
  terminateRepairer,
  unlockRepairer,
} from 'api/resources/repairer';
import { getChangelog } from 'api/resources/changelog';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const getUserCashKey = () => 'user';

export const useUser = ({
  onSuccess,
}: { onSuccess?: (user: User) => void } = {}) => {
  const user = useAppSelector((rootState) => rootState.auth.user);
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    getUserCashKey(),
    getUser,
    {
      staleTime: dayjs.duration(5, 'm').asMilliseconds(),
      onSuccess,
      enabled: !!user?.accessToken,
    }
  );

  return {
    user: data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};

export const useHelpInformation = () => {
  const { data, isLoading } = useQuery('helpInformation', getHelpContext);
  return {
    message: data?.message,
    isLoading: isLoading,
  };
};

export const getMessageQueuesCacheKey = (searchValue: string) => [
  'messageQueues',
  searchValue,
];

export const useMessageQueues = (
  searchValue: string,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getMessageQueuesCacheKey(searchValue),
    () => getMessageQueues(searchValue),
    { enabled: !!searchValue, onError }
  );
  return {
    messages: data ?? undefined,
    isMessagesLoading: !!searchValue && (isLoading || isFetching),
    refetch,
  };
};

const getMessageArchiveCacheKey = (searchValue: string) => [
  'Message_Archives',
  searchValue,
];

export const useMessageArchives = (
  searchValue: string,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getMessageArchiveCacheKey(searchValue),
    () => getMessageArchives(searchValue),
    { enabled: false, onError }
  );
  return {
    messageArchives: data,
    isMessageArchivesLoading: isLoading || isFetching,
    refetchMessageArchives: refetch,
  };
};

export const getFnolQueuesCacheKey = (searchValue: string) => [
  'fnolQueues',
  searchValue,
];

export const getFnolXMLCacheKey = (notificationid?: number) => [
  'fnolXML',
  notificationid,
];

export const useFnolQueues = (
  searchValue: string,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getFnolQueuesCacheKey(searchValue),
    () => getFnolQueues(searchValue),
    { enabled: !!searchValue, onError }
  );
  return {
    fnols: data ?? undefined,
    isFnolsLoading: isLoading || isFetching,
    refetch,
  };
};

const getMessageQueuesXMLCacheKey = (
  queueId?: number,
  queueType?: QueueType
) => ['messageQueueXML', queueId, queueType];

export const useMessageQueuesXML = (
  queueId?: number,
  queueType?: QueueType
) => {
  const isEnabled = !!queueId && !!queueType;
  const { data, isLoading, isFetching, refetch } = useQuery(
    getMessageQueuesXMLCacheKey(queueId, queueType),
    () => getMessageQueuesXML(queueId, queueType),
    { enabled: isEnabled }
  );
  return {
    message: data ?? undefined,
    isMessageLoading: isEnabled && (isLoading || isFetching),
    refetch,
  };
};

export const useFnolXML = (
  notificationid?: number
) => {
  const isEnabled = !!notificationid;
  const { data, isLoading, isFetching, refetch } = useQuery(
    getFnolXMLCacheKey(notificationid),
    () => getFnolXML(notificationid),
    { enabled: isEnabled }
  );
  return {
    message: data ?? undefined,
    isMessageLoading: isEnabled && (isLoading || isFetching),
    refetch,
  };
};

const getFNOLDetailsCacheKey = (notificationid: number) => [
  'FNOL_Details',
  notificationid,
];

export const useFNOLDetails = (
  notificationid: number,
  onError?: (error: AxiosError) => void
) => {
  const isEnabled = !!notificationid;
  const { data, isLoading, isFetching, refetch } = useQuery(
    getFNOLDetailsCacheKey(notificationid),
    () => getFNOLDetails(notificationid),
    { enabled: isEnabled, onError }
  );
  return {
    fnol_details: {
      fnol: data?.fnol || undefined,
      repair: data?.repair || undefined,
      updates: data?.updates || [],
    },
    isFnolDetailsLoading: isLoading || isFetching,
    refetch,
  };
};

const getFNOLArchiveCacheKey = (notificationId: number, registration: string, repairCode: string) => [
  'FNOL_Archives',
  notificationId,
  registration,
  repairCode
];

export const useFNOLArchives = (
  notificationId: number,
  registration: string,
  repairCode: string,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getFNOLArchiveCacheKey(notificationId, registration, repairCode),
    () => getFNOLArchives(notificationId, registration, repairCode),
    { enabled: false, onError }
  );
  return {
    fnolArchives: data,
    isFnolDetailsLoading: isLoading || isFetching,
    refetchFNOLArchives: refetch,
  };
};

export const getMaintenanceMessagesCacheKey = (
  startDate?: string | null,
  endDate?: string | null,
  includeDeleted?: string | null
) => ['maintenanceMessages', startDate, endDate, includeDeleted];

export const useMaintenanceMessages = (
  startDate: string | null,
  endDate: string | null,
  includeDeleted: string | null,
  onError?: (error: AxiosError) => void
) => {
  const isEnabled = !!startDate && !!endDate;
  const { data, isLoading, isFetching, refetch } = useQuery(
    getMaintenanceMessagesCacheKey(startDate, endDate, includeDeleted),
    () => getMaintenanceMessages(startDate, endDate, includeDeleted),
    { enabled: isEnabled, onError }
  );
  return {
    maintenanceMessages: data ?? undefined,
    isMaintenanceMessagesLoading: isEnabled ? isLoading || isFetching : false,
    refetch,
  };
};

const getMaintenanceMessageCacheKey = (messageId?: number | null) => [
  'maintenanceMessages',
  messageId,
];

export const useMaintenanceMessage = (messageId?: number | null) => {
  const isEnabled = typeof messageId == 'number';
  const { data, isLoading, isFetching, refetch } = useQuery(
    getMaintenanceMessageCacheKey(messageId),
    () => getMaintenanceMessage(messageId),
    { enabled: isEnabled }
  );
  return {
    maintenanceMessage: data ?? undefined,
    isMaintenanceMessageLoading: isEnabled ? isLoading || isFetching : false,
    refetch,
  };
};

export const useCreateMaintenanceMessages = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminMaintenanceMessageModel) => createMaintenanceMessages(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getMaintenanceMessagesCacheKey());
      },
    }
  );
  return { createMaintenanceMessage: mutateAsync, isCreating: isLoading };
};

export const useUpdateMaintenanceMessages = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminMaintenanceMessageModel) => updateMaintenanceMessages(data),
    {
      onSuccess: async (_data, message) => {
        await queryClient.invalidateQueries(
          getMaintenanceMessageCacheKey(message.maintenanceMessageId)
        );
        await queryClient.invalidateQueries(getMaintenanceMessagesCacheKey());
      },
    }
  );
  return { updateMaintenanceMessage: mutateAsync, isUpdating: isLoading };
};

export const useRemoveMaintenanceMessage = () => {
  const { mutateAsync, isLoading } = useMutation(
    ({
      messageId,
    }: {
      messageId: number;
      startDate?: string | null;
      endDate?: string | null;
      includeDeleted?: string | null;
    }) => removeMaintenanceMessage(messageId),
    {
      onSuccess: async (
        _data,
        { messageId, startDate, includeDeleted, endDate }
      ) => {
        if (!!startDate && !!endDate && !!includeDeleted)
          await queryClient.invalidateQueries(
            getMaintenanceMessagesCacheKey(startDate, endDate, includeDeleted)
          );
        await queryClient.invalidateQueries(
          getMaintenanceMessageCacheKey(messageId)
        );
      },
    }
  );
  return { removeMaintenanceMessage: mutateAsync, isRemoving: isLoading };
};

export const useWPNameChange = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminWPNameChangeModel[]) => wpNameChange(data)
  );
  return { wpChange: mutateAsync, isUpdating: isLoading };
};

export const useJobStatusUpdate = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminJobStatusUpdateModel[]) => jobStatusUpdate(data)
  );
  return { jobStatus: mutateAsync, isUpdating: isLoading };
};

const getChangelogDetailsCacheKey = () => ['Changelog_Details'];

export const useChangelogDetails = () => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getChangelogDetailsCacheKey(),
    () => getChangelog()
  );
  return {
    changelog_details: data ?? undefined,
    isChangeloglDetailsLoading: isLoading || isFetching,
    refetch,
  };
};

export const useCreateRepairer = () => {
  const { mutateAsync, isLoading } = useMutation((data: AdminRepairerModel) =>
    createRepairer(data)
  );
  return { createRepairer: mutateAsync, isCreating: isLoading };
};

export const getRepairersCacheKey = (repairers?: string | null) => [
  'repairers',
  repairers,
];

export const useRepairers = (
  searchValue?: string | null,
  onError?: (error: AxiosError) => void
) => {
  const isEnabled = !!searchValue;
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRepairersCacheKey(searchValue),
    () => getRepairers(searchValue),
    { enabled: isEnabled, onError }
  );
  return {
    repairers: data ?? undefined,
    isRepairersLoading: isEnabled ? isLoading || isFetching : false,
    refetch,
  };
};

export const getRepairerCacheKey = (repairerId?: number | null) => [
  'repairers',
  repairerId,
];

export const useRepairer = (
  repairerId?: number | null,
  onError?: (error: AxiosError) => void
) => {
  const isEnabled = !!repairerId;
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRepairerCacheKey(repairerId),
    () => getRepairer({ repairerId }),
    { enabled: isEnabled, onError }
  );
  return {
    repairer: data ?? undefined,
    isRepairerLoading: isEnabled ? isLoading || isFetching : false,
    refetch,
  };
};

export const getConnectionSettingsCacheKey = ( workProviderId?: number | null, repairerId?: number | null, category?: string | null) => [
  'connectionSettings',
  workProviderId,
  repairerId,
  category,
];

export const useConnectionSettings = (
  workProviderId?: number | null,
  repairerId?: number | null,
  category?: string | null,
) => {
  const isEnabled = !!workProviderId;
  const { data, isLoading } = useQuery(
    getConnectionSettingsCacheKey(workProviderId, repairerId, category),
    () => getConnectionSettings({ workProviderId, repairerId, category }),
    { enabled: isEnabled }
  );
  return {
    connectionSettings: data ?? undefined,
    isSettingLoading: isLoading,
  };
};

export const useEditRepairer = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminRepairerModel) => editRepairer(data),
    {
      onSuccess: async (_data, { repairerId }) => {
        await queryClient.invalidateQueries(getRepairerCacheKey(repairerId));
      },
    }
  );
  return { updateRepairer: mutateAsync, isUpdating: isLoading };
};

export const useLockRepairer = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
}) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminLockRepairerModel) => lockRepairer(data),
    {
      onSuccess: async (_data, { repairerId }) => {
        onSuccess();
        await queryClient.invalidateQueries(getRepairerCacheKey(repairerId));
      },
      onError,
    }
  );
  return { lock: mutateAsync, isLocking: isLoading };
};

export const useUnlockRepairer = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
}) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminLockRepairerModel) => unlockRepairer(data),
    {
      onSuccess: async (_data, { repairerId }) => {
        onSuccess();
        await queryClient.invalidateQueries(getRepairerCacheKey(repairerId));
      },
      onError,
    }
  );
  return { unlock: mutateAsync, isUnlocking: isLoading };
};

export const useTerminateRepairer = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
}) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminTerminateRepairerModel) => terminateRepairer(data),
    {
      onSuccess: async (_data, { repairerId }) => {
        onSuccess();
        await queryClient.invalidateQueries(getRepairerCacheKey(repairerId));
      },
      onError,
    }
  );
  return { terminate: mutateAsync, isTerminating: isLoading };
};

export const useRemoveConnections = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminRemoveRepairerConnectionModel) => removeConnection(data),
    {
      onSuccess: async (_data, { repairerId }) => {
        await queryClient.invalidateQueries(getRepairerCacheKey(repairerId));
      },
    }
  );
  return { removeConnection: mutateAsync, isRemoving: isLoading };
};

export const useRepairerWorkProviders = () => {
  const { data, isLoading } = useQuery(
    ['repairerWorkProviders'],
    getRepairerWorkProviders,
    { refetchOnMount: true }
  );
  return { workProviders: data ?? [], isWorkProviderLoading: isLoading };
};

export const getFnolConfigsList = (workProviderID: number | undefined, repairerId: number) => [
  'fnolconfigs',
  workProviderID,
  repairerId
];

export const useGetFnolConfigs = (workProviderID: number | undefined, repairerId: number) => {
  const { data, isLoading } = useQuery(
    getFnolConfigsList(workProviderID, repairerId),
    () => getFnolConfigs({workProviderID, repairerId}),
    { enabled:!!workProviderID}
  );
  return { fnolConfigs: data ?? [], isWorkProviderLoading: isLoading };
};


const getAvailableWorkProvidersCacheKey = (siteCode: string) => [
  'availableWorkProviders',
  siteCode,
];
export const useAvailableWorkProviders = (siteCode: string) => {
  const { data, isLoading, isFetching } = useQuery(
    getAvailableWorkProvidersCacheKey(siteCode),
    () => getAvailableWorkProviders(siteCode),
    { refetchOnMount: true }
  );
  return {
    availableWorkProviders: data ?? [],
    isAvailableWorkProviderLoading: isLoading || isFetching,
  };
};

export const useAddConnection = (repairerId: number, siteCode: string) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminAddRepairerConnectionModel) => addConnection(data),
    {
      onSuccess: () => {
        queryClient.removeQueries(getRepairerCacheKey(repairerId));
        queryClient.removeQueries(getAvailableWorkProvidersCacheKey(siteCode));
      },
    }
  );
  return { connectionAdd: mutateAsync, isAdding: isLoading };
};
export const useAddFNOL = (repairerId: number) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminAddExternalConfigurationModel) => addFnol(data),
    {
      onSuccess: () => {
        queryClient.removeQueries(getRepairerCacheKey(repairerId));
      },
    }
  );
  return { FNOLAdd: mutateAsync, isAdding: isLoading };
};

export const useCompanies = () => {
  const { data, isLoading } = useQuery(['companies'], getCompanies);
  return { companiesInfo: data ?? [], isCompaniesLoading: isLoading };
};

export const useReactivateRepairer = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
}) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: AdminReactivateRepairerModel) => reactivateRepairer(data),
    {
      onSuccess: async (_res, { repairerId }) => {
        onSuccess();
        await queryClient.invalidateQueries(getRepairerCacheKey(repairerId));
      },
      onError,
    }
  );
  return { reactivateRepairer: mutateAsync, isReactivating: isLoading };
};
