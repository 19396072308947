import styled from '@emotion/styled';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import {
  MainPageContainer,
  pagePaddingLeft,
  UpperInfoContainer,
} from 'components/PageLayout';
import { orderBy } from 'lodash';
import { Heading } from 'components/Typography';
import { useFNOLArchives, useFNOLDetails, useUser } from 'pages/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  notificationDetailQueueHeader,
  notificationDetailRepairOverviewHeader,
  notificationDetailTableHeader,
  useFnolDetailsParams,
} from './types';
import { Permit, SortOrder } from 'api/resources/models/User';
import { SortObject } from 'components/types';
import {
  NotificationDetailFNOL,
  NotificationDetailQueue,
  NotificationDetailRepairOverview,
  QueueType,
} from 'api/resources/models/AutoGenerated';
import { useEffect, useMemo, useState } from 'react';
import { createFnolDetailsURL, FNOLDetailsParams } from 'core/routes';
import { Container } from 'components/Layout';
import { BackButton, PrimaryButton } from 'components/Button';
import { useTheme } from '@emotion/react';
import { formatDateTime } from 'utils/helpers';
import { XMLPopup } from 'pages/MessageQueues/XMLPopup';
import { FnolXMLPopup } from './FnolXMLPopup';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import SearchIcon from '@mui/icons-material/Search';

const historyQues = ['CAPSInboundHistory', 'RestHistory', 'WcfHistory'];

const useDetailsParams = () => {
  const { notificationId } = useParams<FNOLDetailsParams>();
  const id = Number(notificationId);
  return {
    notificationId: isNaN(id) ? 'notificationId must be a number' : id,
  };
};

export const FNOLDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { previousPath } = location.state || '';
  const urlParams = useFnolDetailsParams(location.search);

  const { notificationId } = useDetailsParams();
  if (typeof notificationId == 'string') throw new Error(notificationId);

  const { user, isLoading, isFetching } = useUser();
  const { fnol_details, isFnolDetailsLoading } = useFNOLDetails(
    notificationId,
    handleError
  );

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? '',
    sortOrder: urlParams.sortOrder ?? undefined,
  });
  const [sortedFnolUpdates, setSortedFnolUpdates] = useState(
    fnol_details.updates
  );
  const [showBladePopup, setShowBladePopup] = useState<{
    show: boolean;
    id: number;
    type: QueueType;
  } | null>(null);
  const [showFnolXmlPopup, setShowFnolXmlPopup] = useState<{
    show: boolean;
    id: number;
    type?: QueueType;
  } | null>(null);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();
  const [totalCount, setTotalCount] = useState<number>(0);

  const { fnolArchives, refetchFNOLArchives } = useFNOLArchives(
    notificationId,
    fnol_details?.repair?.vehicleReg ?? fnol_details?.fnol?.registration ?? '',
    fnol_details?.repair?.repairCode ?? fnol_details?.fnol?.registration ?? '',
    handleFnolArchivesError
  );
  const [archiveFetchDisabled, setArchiveFetchDisabled] =
    useState<boolean>(false);

  const fetchFromArchive = () => {
    setArchiveFetchDisabled(true);
    refetchFNOLArchives();
  };

  useEffect(() => {
    setSortedFnolUpdates(sortDetailQueue(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fnol_details?.updates, currentPage, itemsPerPage, fnolArchives]);

  useEffect(() => {
    navigateToFnolDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  const XML_VIEW_PERMISSION = useMemo(
    () =>
      user?.userPermits?.find((permit) => permit.code == Permit.CA_QUEUE_CONTENTS_VIEW),
    [user]
  );

  const previousPage = useMemo(() => {
    if (previousPath?.includes?.('fnol')) return 'to FNOL';
    else if (previousPath?.includes?.('messageQueues'))
      return 'to Message Queues';
    else return '';
  }, [previousPath]);

  const isBusy = isFnolDetailsLoading || isLoading || isFetching;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <BackButton text={`Back ${previousPage}`} />
        <Heading className="subheading" fontSize={'s'}>
          Notification Details
        </Heading>
      </UpperInfoContainer>

      <DataGrid
        headers={notificationDetailTableHeader}
        data={fnol_details.fnol && [fnol_details.fnol]}
        isLoading={isBusy}
        customCellRender={renderCustomCellNotification}
        onRowClick={onFnolRowClick}
      />
      {!isBusy && !fnol_details?.fnol && (
        <InfoText>{errorMessages.noFnolsFound}</InfoText>
      )}

      <FnolXMLPopup
        id={showFnolXmlPopup?.id}
        isOpen={showFnolXmlPopup?.show}
        onClose={() => setShowFnolXmlPopup(null)}
      />

      <TitleContainer>
        <Heading className="subheading">Repair Overview</Heading>
      </TitleContainer>
      <DataGrid
        headers={notificationDetailRepairOverviewHeader}
        data={fnol_details.repair && [fnol_details.repair]}
        isLoading={isBusy}
        customCellRender={renderCustomCellRepairOverview}
      />
      {!isBusy && !fnol_details?.repair && (
        <InfoText>{errorMessages.noFnolRepairersFound}</InfoText>
      )}

      <TitleContainer>
        <Heading className="subheading">Updates</Heading>
        <FetchArchiveButton onClick={fetchFromArchive} disabled={archiveFetchDisabled}>
          <SearchIcon />
          Fetch from archive
        </FetchArchiveButton>
      </TitleContainer>
      <DataGrid
        headers={notificationDetailQueueHeader}
        data={sortedFnolUpdates}
        isLoading={isBusy}
        onSort={sort}
        sortObject={sortObject}
        onRowClick={onRowClick}
        customBgColorRender={customBgColorRender}
        customCellRender={renderCustomCellUpdates}
        currentPage={currentPage}
        totalCount={totalCount}
        onPageChange={(page) => setCurrentPage(page)}
        onItemsPerPageChange={setItemsPerPage}
      />
      {!isBusy &&
        (!fnol_details?.updates || fnol_details?.updates?.length <= 0) && (
          <InfoText>{errorMessages.noMessagesFound}</InfoText>
        )}

      <XMLPopup
        id={showBladePopup?.id}
        type={showBladePopup?.type}
        isOpen={showBladePopup?.show}
        onClose={() => setShowBladePopup(null)}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </MainPageContainer>
  );

  function onRowClick(row: NotificationDetailQueue) {
    setShowFnolXmlPopup(null);
    if (XML_VIEW_PERMISSION && row.id && row.queue && row.messageType)
      setShowBladePopup({
        show: true,
        id: row.id,
        type: row.queue,
      });
  }

  function onFnolRowClick(row: NotificationDetailFNOL) {
    setShowBladePopup(null);
    if (XML_VIEW_PERMISSION && row.id)
      setShowFnolXmlPopup({
        show: true,
        id: row.id,
        type: undefined,
      });
  }

  function customBgColorRender({
    baseRender,
    row,
  }: {
    baseRender: (color?: string) => JSX.Element;
    row: NotificationDetailQueue;
  }) {
    let color = '';
    if (row.queue) {
      const queue = row.queue;
      if (queue?.toString() == 'Rest') color = theme.palette.fnolDetails.blue;
      else if (historyQues.includes(queue?.toString()))
        color = theme.palette.fnolDetails.gray;
      else if (queue?.toString() == 'PoisonQueue')
        color = theme.palette.fnolDetails.red;
      else color = theme.palette.fnolDetails.orange;
    }
    return baseRender(color);
  }

  function renderCustomCellNotification({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof NotificationDetailFNOL;
    row: NotificationDetailFNOL;
  }) {
    if (headerId == 'receivedDate' && row.receivedDate)
      return <>{formatDateTime(row.receivedDate)}</>;
    if (headerId == 'readDate' && row.readDate)
      return <>{formatDateTime(row.readDate)}</>;
    return baseRender();
  }

  function renderCustomCellRepairOverview({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof NotificationDetailRepairOverview;
    row: NotificationDetailRepairOverview;
  }) {
    if (headerId == 'lastUpdatedDTG' && row.lastUpdatedDTG)
      return <>{formatDateTime(row.lastUpdatedDTG)}</>;
    return baseRender();
  }

  function renderCustomCellUpdates({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof NotificationDetailQueue;
    row: NotificationDetailQueue;
  }) {
    if (headerId == 'readDate' && row.readDate)
      return <>{formatDateTime(row.readDate)}</>;
    if (headerId == 'receivedDate' && row.receivedDate)
      return <>{formatDateTime(row.receivedDate)}</>;
    return baseRender();
  }

  function sortDetailQueue(sortObj: SortObject) {
    const currentListed = currentPage * itemsPerPage;
    const fnolUpdates = [...fnol_details?.updates ?? [], ...fnolArchives ?? []]
    const orderedList = orderBy(
      fnolUpdates,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
    setTotalCount(orderedList?.length ?? 0)
    return orderedList?.slice(currentListed, currentListed + itemsPerPage);
  }

  function sort(sortValue: keyof NotificationDetailQueue) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedFnolUpdates(sortDetailQueue(sortObj));
      return sortObj;
    });
  }

  function navigateToFnolDetails() {
    if (typeof notificationId == 'number')
      navigate(
        createFnolDetailsURL(
          sortObject.sortString ?? undefined,
          sortObject.sortOrder,
          notificationId
        ),
        { replace: true, state: location.state || '' }
      );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(error: AxiosError) {
    setToastState({
      message: errorMessages.fnolDetails,
      isOpen: true,
      severity: 'error',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleFnolArchivesError(error: AxiosError) {
    setToastState({
      message: errorMessages.archives,
      isOpen: true,
      severity: 'error',
    });
  }
};

export const TitleContainer = styled(Container)`
  padding-top: ${({ theme }) => theme.padding.xl};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  background: ${({ theme }) => theme.palette.background.default};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 10px 20px 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 10px 15px 0 15px;
  }
`;

const FetchArchiveButton = styled(PrimaryButton)`
  width: 200px;
  height: 30px;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-right: 10px;
  margin-left: 20px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
  }
`;
