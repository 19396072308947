import { signinRedirect } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import store, { useAppSelector } from 'core/store';
import { Navigate } from 'react-router-dom';

export function Login() {
  localStorage.removeItem('logout-event');
  const user = useAppSelector((state) => state.auth.user);
  
  if (!user) signinRedirect();

  return user ? <Navigate to={ROUTES.redirectHandler} /> : null;
}
